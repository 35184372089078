<template>
  <div>
    <article class="tile is-child box detail-page tile">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Period Start</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <v-date-picker v-model="selectedFromDate"
                :masks="formats"
                :popover="{ placement : 'right-start' }"
                :attributes="attrs"
                :locale="$userInfo.locale">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input :class="{ 'is-danger' : $v.withHoldStatementFilter.selectedFromDate.$error }"
                        type="text"
                        class="input"
                        placeholder="Date From..."
                        :value="inputValue"
                        v-on="inputEvents">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                    <span
                      class="help is-danger"
                      v-if="$v.withHoldStatementFilter && !$v.withHoldStatementFilter.selectedFromDate.required">GST period start date is required.
                    </span>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Period End</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <v-date-picker v-model="selectedToDate"
                :masks="formats"
                :popover="{ placement : 'right-start' }"
                :attributes="attrs"
                :locale="$userInfo.locale">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input
                        :class="{ 'is-danger' : $v.withHoldStatementFilter.selectedToDate.$error }"
                        type="text"
                        class="input"
                        placeholder="Date To..."
                        :value="inputValue"
                        v-on="inputEvents">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                    <span class="help is-danger"
                      v-if="$v.withHoldStatementFilter && !$v.withHoldStatementFilter.selectedToDate.required">GST period end date is required.
                    </span>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Vendor</label>
        </div>
        <div class="field-body">
          <div class="field">
            <multiselect
              :class="{ 'is-danger' : $v.withHoldStatementFilter.selectedVendor.$error }"
              v-model="selectedVendor"
              deselect-label="Can't remove this value"
              track-by="name"
              label="name"
              :show-labels="false"
              :options="vendors"
              :loading="isVendorLoading"
              :internal-search="false"
              :searchable="true"
              :allow-empty="false"
              :max-height="150"
              @select="selectVendor"
              @remove="removeVendor"
              @search-change="getVendorDropdownFilter"
              placeholder="Select a factorer" />
            <span
              class="help is-danger"
              v-if="$v.withHoldStatementFilter && !$v.withHoldStatementFilter.selectedVendor.required">Vendor required.
            </span>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import Guid from '@/components/Guid'
import Multiselect from 'vue-multiselect'
import VendorService from '@/views/vendor/VendorService'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import _debounce from 'lodash.debounce'
import { FocusInserted } from '@/components/directives'
import _cloneDeep from 'lodash/cloneDeep'
import StoreMixin from './storeMixin'

export default {
  name: 'GstWithHoldingStatementDetail',
  inject: ['$vv'],
  directives: {
    FocusInserted
  },
  components: {
    Multiselect
  },
  mixins: [DateTimeFiltersMixin, StoreMixin],
  props: {
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      vendors:[],
      selectedVendor: {
        abn: '',
        id: '',
        name: '',
        totalRows: ''
      },
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      assetFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      isVendorLoading: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedFromDate(newVal, oldVal) {
      if (newVal) {
        this.innerValue.selectedFromDate = newVal.toISOString().split('.')[0] + 'Z'
        this.setWHPeriodStart(newVal)
      } else {
        this.innerValue.selectedFromDate = null
      }
    },
    selectedToDate(newVal, oldVal) {
      if (newVal) {
        this.innerValue.selectedToDate = newVal.toISOString().split('.')[0] + 'Z'
        this.setWHPeriodEnd(newVal)
      } else {
        this.innerValue.selectedToDate = null
      }
    }
  },
  async created() {
    this.selectedFromDate = this.whPeriodStart
    this.selectedToDate = this.whPeriodEnd
    this.getVendorsDropdown()
  },
  mounted() {
    this.$v.withHoldStatementFilter.$touch()
  },
  methods: {
    async selectVendor(selected) {
      this.innerValue.selectedVendor = selected
    },
    removeVendor() {},
    async getVendorsDropdown() {
      this.isVendorLoading = true
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      if (this.assetFilter.id !== Guid.empty()) {
        this.selectedVendor = this.vendors.find(c => c.id === this.innerValue.assetId)
      }
      this.isVendorLoading = false
      return this.vendors
    },
    getVendorDropdownFilter: _debounce(async function(query) {
      this.isVendorLoading = true
      this.assetFilter.name = query
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      this.isVendorLoading = false
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
.tile {
  min-height: 350px;
}
</style>
