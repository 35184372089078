<template>
  <div>
    <article class="tile is-child box detail-page tile">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <input class="is-checkradio is-info"
              v-model="innerValue.payAcqSupplyCheck"
              :class="{ 'has-background-color': selectedAcq }"
              id="acqReport"
              type="checkbox"
              @click="selectAcqReport">
            <label for="acqReport">Pay Acquisitions/Supplies</label>
          </div>
        </div>
        <div class="field-body">
          <div class="field">
            <input class="is-checkradio is-info"
              v-model="innerValue.payWithHoldCheck"
              :class="{ 'has-background-color': selectedWithHold }"
              type="checkbox"
              id="withHold"
              @click="selectWithHoldReport">
            <label for="withHold">Pay With-Holding</label>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Payment Date</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <v-date-picker v-model="selectedPaymentDate"
                :masks="formats"
                :popover="{ placement : 'right-start' }"
                :attributes="attrs"
                :locale="$userInfo.locale">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        :class="{ 'is-danger' : $v.entity.paymentDate.$error }"
                        class="input"
                        placeholder="Payment Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                    <span
                      class="help is-danger"
                      v-if="$v.entity && !$v.entity.paymentDate.required">GST payment date is required.
                    </span>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Acquisitions</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <vue-numeric class="input has-text-right"
                :value="innerValue.acqAmount"
                type="text"
                placeholder=""
                :precision="2" />
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Supplies</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <vue-numeric class="input has-text-right"
                :value="innerValue.suppliesAmount"
                type="text"
                placeholder=""
                :precision="2" />
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Cheque No.</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <input class="input is-uppercase"
                v-model="innerValue.cheque"
                type="text"
                placeholder="">
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Bank Account</label>
        </div>
        <div class="field-body">
          <div class="field-body">
            <div class="field">
              <multiselect v-if="glBankAccountListCombo"
                v-model="innerValue.selectedBankAccount"
                :options="glBankAccountListCombo"
                :loading="isGlBankAccountLoading"
                open-direction="above"
                placeholder="Select account"
                label="displayName"
                deselect-label="Can't remove this value"
                track-by="accountNo"
                :allow-empty="false">
                <span class="has-text-danger"
                  slot="noResult">Bank account not found.</span>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { FocusInserted } from '@/components/directives'
import Multiselect from 'vue-multiselect'
import _cloneDeep from 'lodash/cloneDeep'
import FactorInvoiceService from '../factorinvoice/FactorInvoiceService'
import VueNumeric from '@/components/VueNumeric'

export default {
  name: 'GstPayWithHoldingDetail',
  inject: ['$vv'],
  directives: {
    FocusInserted
  },
  components: {
    Multiselect,
    VueNumeric
  },
  mixins: [],
  props: {
    value: null,
    gstBasis: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedPaymentDate: null,
      gstDates: [],
      selectedAcq: false,
      selectedWithHold: false,
      selectedReports: [],
      isGstLoading: false,
      glBankAccountListCombo: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedPaymentDate(newVal, oldVal) {
      if (newVal) {
        this.innerValue.paymentDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.paymentDate = null
      }
    }
  },
  async created() {
    this.GetGLBankAccountsDropdown()
  },
  mounted() {
    this.$v.entity.$touch()
  },
  methods: {
    async GetGLBankAccountsDropdown() {
      this.isGlBankAccountLoading = true
      this.glBankAccountListCombo = await FactorInvoiceService.getGLBankAccountsDropdownProto(this.$userInfo.companyId)
      this.isGlBankAccountLoading = false
    },
    removeGstDate() {
      this.innerValue.selectedToDate = null
    },
    selectAcqReport() {
      if (this.selectedAcq == false) {
        this.selectedAcq = true
        return
      } else if (this.selectedAcq == true) {
        this.selectedAcq = false
        return
      }
    },
    selectWithHoldReport() {
      if (this.selectedWithHold == false) {
        this.selectedWithHold = true
        return
      } else if (this.selectedWithHold == true) {
        this.selectedWithHold = false
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
