<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Choose Report
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <div class="select is-fullwidth"
                v-show="gstPeriod!=0">
                <Multiselect v-if="options"
                  v-model="selectedMonth"
                  :options="options"
                  placeholder="Select a month"
                  label="month"
                  :show-labels="false"
                  @select="selectMonth">
                  <template slot="option"
                    slot-scope="props">
                    <span class="tag is-pulled-right is-success">
                      {{ props.option.year }}
                    </span>
                    <span>{{ `${props.option.month}` }}</span>
                  </template>
                </Multiselect>
                <span v-if="selectedMonth"
                  class="help is-success">GST Period: {{ selectedMonth.firstDay }} To: {{ selectedMonth.lastDay }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="withHolding">
            <span class="icon">
              <i class="mdi mdi-file-document mdi-18px" />
            </span>
            <span>With-Holding</span>
          </button>
        </div>
        <div class="control">
          <button class="button is-primary"
            @click="rePrint">
            <span class="icon">
              <i class="mdi mdi-file-document mdi-18px" />
            </span>
            <span>Reprint</span>
          </button>
        </div>
        <div class="control">
          <button class="button is-primary"
            :disabled="!selectedMonth"
            @click="print">
            <span class="icon">
              <i class="mdi mdi-file-document mdi-18px" />
            </span>
            <span>Print</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import Multiselect from 'vue-multiselect'
import { DateTime } from 'luxon'

export default {
  name: 'GstReportSideMenu',
  components: {
    AppSideMenu,
    Multiselect
  },
  props: {
    gstPeriod: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selectedMonth: '',
      options: []
    }
  },
  computed: {},
  async created() {
    this.options = await this.getMonthOption()
  },
  methods: {
    withHolding() {
      this.$emit('printWH')
    },
    rePrint() {
      this.$emit('reprint')
    },
    print() {
      this.$emit('print', this.selectedMonth)
    },
    async getMonthOption() {
      let today = new Date()
      let thisMonth = today.getMonth()
      let thisYear = today.getFullYear()
      let monthOptions = []
      let month = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December')
      month.map(function(item, index) {
        monthOptions.push({
          index: month.indexOf(month[thisMonth]),
          month: month[thisMonth],
          year: thisYear,
          firstDay: DateTime.fromJSDate(new Date(thisYear, month.indexOf(month[thisMonth]), 1)).toLocaleString(),
          lastDay: DateTime.fromJSDate(new Date(thisYear, month.indexOf(month[thisMonth]) + 1, 0)).toLocaleString(),
          firstDayISO:DateTime.fromJSDate(new Date(thisYear, month.indexOf(month[thisMonth]), 1)).toISODate(),
          lastDayISO: DateTime.fromJSDate(new Date(thisYear, month.indexOf(month[thisMonth]) + 1, 0)).toISODate()
        })
        thisMonth--
        if (thisMonth < 0) {
          thisMonth = 11
          thisYear--
        }
      })
      if (this.gstPeriod == 4) {
        return monthOptions.filter(item => (item.index + 1) % 3 == 0)
      } else if (this.gstPeriod == 12) {
        return monthOptions
      }
    },
    selectMonth(selected) {
      this.selectedMonth = selected
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
