<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    :min-height="35"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <gst-print-detail v-model="filter"
        :gst-basis="gstBasis" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="print()"
        data-tooltip="Print">Print</button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Cancel">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { mapGetters } from 'vuex'
import storeMixin from './storeMixin'
import _debounce from 'lodash.debounce'
import { KeyValuePairModel } from '@/classes/viewmodels'
import GstPrintDetail from './GstPrintDetail'
import GstReportValidation from './GstReportValidation'
import GstReportService from './GstReportService'
import { DateTime } from 'luxon'

export default {
  name: 'GstPrintModal',
  components: {
    BaseModalEx,
    GstPrintDetail
  },
  mixins: [GstReportValidation, storeMixin],
  props: {
    gstBasis: {
      type: String,
      default: ''
    },
    selectedMonth: null,
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      filter: {
        gstBasis: this.gstBasis,
        startDate: this.selectedMonth.firstDayISO,
        endDate: this.selectedMonth.lastDayISO,
        type: 'PRINT',
        checkAcqSupplies: true,
        checkWithHold: true
      },
      isActive: true
    }
  },
  computed: {
    ...mapGetters(['licenceExpired']),
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    filter: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
        }
      }, 500),
      deep: true
    }
  },
  async created() {},
  methods: {
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|gstreport|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|gstreport|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    close() {
      this.clearSessionStorage()
      this.$emit('close')
    },
    async print() {
      const title = 'GST Report'
      let checkPrint = await GstReportService.checkGstTransaction(this.filter)

      //report param
      const paramsSupply = {
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: 'GST Supplies Report',
        //STdate: this.selectedMonth.firstDay,
        //ENDdate: this.selectedMonth.lastDay,
        STdate: DateTime.fromISO(this.selectedMonth.firstDayISO).toISODate(),
        ENDdate: DateTime.fromISO(this.selectedMonth.lastDayISO).toISODate(),
        COMPANYID: this.$userInfo.companyId,
        GSTType: 'PRINT',
        GSTBasis: this.gstBasis
      }

      const paramsAcq = {
        // STdate: this.selectedMonth.firstDay,
        // ENDdate: this.selectedMonth.lastDay,
        STdate: DateTime.fromISO(this.selectedMonth.firstDayISO).toISODate(),
        ENDdate: DateTime.fromISO(this.selectedMonth.lastDayISO).toISODate(),
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: 'GST Acquisitions Report',
        COMPANYID: this.$userInfo.companyId,
        GSTType: 'PRINT',
        GSTBasis: this.gstBasis
      }

      const paramWithHold = {
        // STdate: this.selectedMonth.firstDay,
        // ENDdate: this.selectedMonth.lastDay,
        STdate: DateTime.fromISO(this.selectedMonth.firstDayISO).toISODate(),
        ENDdate: DateTime.fromISO(this.selectedMonth.lastDayISO).toISODate(),
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: 'With-Holding Report',
        COMPANYID: this.$userInfo.companyId,
        GSTType: 'PRINT'
      }

      let reportSupply = {
        parameters: KeyValuePairModel.convertToKeyValuePairs(paramsSupply),
        parameterId: this.$guid.newGuid(),
        reportName: 'AT_GSTSuppliesReportV2',
        reportTitle: 'GST Supplies Report'
      }

      let reportAcq = {
        parameters: KeyValuePairModel.convertToKeyValuePairs(paramsAcq),
        parameterId: this.$guid.newGuid(),
        reportName: 'AT_GSTAcquisitionsV2',
        reportTitle: 'GST Acquisitions Report'
      }

      let reportWithHold = {
        parameters: KeyValuePairModel.convertToKeyValuePairs(paramWithHold),
        parameterId: this.$guid.newGuid(),
        reportName: 'AT_GSTWithHoldingV2',
        reportTitle: 'With-Holding Report'
      }
      let reportList = []

      if (checkPrint.isSuppliesRecord) {
        reportList.push(reportSupply)
      }
      if (checkPrint.isAcqRecord) {
        reportList.push(reportAcq)
      }
      if (checkPrint.isGstWithHold) {
        reportList.push(reportWithHold)
      }

      if (reportList.length == 0) {
        this.$notification.openNotificationWithType('danger', title, 'There are no transactions to report for this period!')
      } else {
        this.$emit('print', reportList)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
