/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.GstReportCheckFilter = (function() {

    /**
     * Namespace GstReportCheckFilter.
     * @exports GstReportCheckFilter
     * @namespace
     */
    var GstReportCheckFilter = {};

    GstReportCheckFilter.GstReportCheckFilterModel = (function() {

        /**
         * Properties of a GstReportCheckFilterModel.
         * @memberof GstReportCheckFilter
         * @interface IGstReportCheckFilterModel
         * @property {string|null} [gstBasis] GstReportCheckFilterModel gstBasis
         * @property {string|null} [startDate] GstReportCheckFilterModel startDate
         * @property {string|null} [endDate] GstReportCheckFilterModel endDate
         * @property {string|null} [type] GstReportCheckFilterModel type
         * @property {boolean|null} [checkAcqSupplies] GstReportCheckFilterModel checkAcqSupplies
         * @property {boolean|null} [checkWithHold] GstReportCheckFilterModel checkWithHold
         */

        /**
         * Constructs a new GstReportCheckFilterModel.
         * @memberof GstReportCheckFilter
         * @classdesc Represents a GstReportCheckFilterModel.
         * @implements IGstReportCheckFilterModel
         * @constructor
         * @param {GstReportCheckFilter.IGstReportCheckFilterModel=} [p] Properties to set
         */
        function GstReportCheckFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * GstReportCheckFilterModel gstBasis.
         * @member {string|null|undefined} gstBasis
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        GstReportCheckFilterModel.prototype.gstBasis = null;

        /**
         * GstReportCheckFilterModel startDate.
         * @member {string|null|undefined} startDate
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        GstReportCheckFilterModel.prototype.startDate = null;

        /**
         * GstReportCheckFilterModel endDate.
         * @member {string|null|undefined} endDate
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        GstReportCheckFilterModel.prototype.endDate = null;

        /**
         * GstReportCheckFilterModel type.
         * @member {string|null|undefined} type
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        GstReportCheckFilterModel.prototype.type = null;

        /**
         * GstReportCheckFilterModel checkAcqSupplies.
         * @member {boolean|null|undefined} checkAcqSupplies
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        GstReportCheckFilterModel.prototype.checkAcqSupplies = null;

        /**
         * GstReportCheckFilterModel checkWithHold.
         * @member {boolean|null|undefined} checkWithHold
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        GstReportCheckFilterModel.prototype.checkWithHold = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GstReportCheckFilterModel _gstBasis.
         * @member {"gstBasis"|undefined} _gstBasis
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        Object.defineProperty(GstReportCheckFilterModel.prototype, "_gstBasis", {
            get: $util.oneOfGetter($oneOfFields = ["gstBasis"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GstReportCheckFilterModel _startDate.
         * @member {"startDate"|undefined} _startDate
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        Object.defineProperty(GstReportCheckFilterModel.prototype, "_startDate", {
            get: $util.oneOfGetter($oneOfFields = ["startDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GstReportCheckFilterModel _endDate.
         * @member {"endDate"|undefined} _endDate
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        Object.defineProperty(GstReportCheckFilterModel.prototype, "_endDate", {
            get: $util.oneOfGetter($oneOfFields = ["endDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GstReportCheckFilterModel _type.
         * @member {"type"|undefined} _type
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        Object.defineProperty(GstReportCheckFilterModel.prototype, "_type", {
            get: $util.oneOfGetter($oneOfFields = ["type"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GstReportCheckFilterModel _checkAcqSupplies.
         * @member {"checkAcqSupplies"|undefined} _checkAcqSupplies
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        Object.defineProperty(GstReportCheckFilterModel.prototype, "_checkAcqSupplies", {
            get: $util.oneOfGetter($oneOfFields = ["checkAcqSupplies"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * GstReportCheckFilterModel _checkWithHold.
         * @member {"checkWithHold"|undefined} _checkWithHold
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @instance
         */
        Object.defineProperty(GstReportCheckFilterModel.prototype, "_checkWithHold", {
            get: $util.oneOfGetter($oneOfFields = ["checkWithHold"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified GstReportCheckFilterModel message. Does not implicitly {@link GstReportCheckFilter.GstReportCheckFilterModel.verify|verify} messages.
         * @function encode
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @static
         * @param {GstReportCheckFilter.IGstReportCheckFilterModel} m GstReportCheckFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GstReportCheckFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.gstBasis != null && Object.hasOwnProperty.call(m, "gstBasis"))
                w.uint32(10).string(m.gstBasis);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(18).string(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(26).string(m.endDate);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(34).string(m.type);
            if (m.checkAcqSupplies != null && Object.hasOwnProperty.call(m, "checkAcqSupplies"))
                w.uint32(40).bool(m.checkAcqSupplies);
            if (m.checkWithHold != null && Object.hasOwnProperty.call(m, "checkWithHold"))
                w.uint32(48).bool(m.checkWithHold);
            return w;
        };

        /**
         * Encodes the specified GstReportCheckFilterModel message, length delimited. Does not implicitly {@link GstReportCheckFilter.GstReportCheckFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @static
         * @param {GstReportCheckFilter.IGstReportCheckFilterModel} message GstReportCheckFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GstReportCheckFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GstReportCheckFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {GstReportCheckFilter.GstReportCheckFilterModel} GstReportCheckFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GstReportCheckFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.GstReportCheckFilter.GstReportCheckFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.gstBasis = r.string();
                    break;
                case 2:
                    m.startDate = r.string();
                    break;
                case 3:
                    m.endDate = r.string();
                    break;
                case 4:
                    m.type = r.string();
                    break;
                case 5:
                    m.checkAcqSupplies = r.bool();
                    break;
                case 6:
                    m.checkWithHold = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a GstReportCheckFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof GstReportCheckFilter.GstReportCheckFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {GstReportCheckFilter.GstReportCheckFilterModel} GstReportCheckFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GstReportCheckFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return GstReportCheckFilterModel;
    })();

    return GstReportCheckFilter;
})();

module.exports = $root;
