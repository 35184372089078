<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="enableSave"
      :show-cancel="false"
      :show-new="false"
      :show-print="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <gst-report-side-menu v-if="companySetting"
          :gst-period="gstPeriod"
          @printWH="openWithHoldingModal"
          @reprint="openReprintModal"
          @print="openPrintModal" />
      </div>
      <div class="column">
        <div class="tile is-parent"
          v-if="!gstPeriod">
          <article class="message is-warning tile is-child detail-page-tile">
            <div class="message-body">
              GST period has not been set up, please go to <strong>Admin <i class="mdi mdi-arrow-right" /> Company</strong>.
            </div>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div class="tabs is-boxed">
              <ul v-if="reports">
                <li v-for="report in reports"
                  :key="report.parameterId"
                  :class="{ 'is-active':reportType === report.reportName }">
                  <a @click="tabClicked(report.reportName)">
                    <span>
                      <span>{{ report.reportTitle }}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div v-if="loadReport">
              <div v-for="report in reports"
                :key="report.parameterId">
                <print-preview-without-header v-show="report.reportName==reportType"
                  @loadFinish="loadFinish()"
                  :report-name="report.reportName"
                  :parameters="report.parameters"
                  :parameter-id="report.parameterId" />
              </div>
            </div>
            <div v-else
              class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>No report to show</p>
            </div>
          </article>
        </div>
      </div>
    </div>
    <gst-with-holding-statement-modal v-if="isGstWithHoldingModalActive"
      :active.sync="isGstWithHoldingModalActive"
      :show-icon="false"
      @close="closeWithHoldModal()"
      @print="printWH">
      <p slot="text-title">Print With-Holding Statement</p>/>
    </gst-with-holding-statement-modal>
    <gst-reprint-modal v-if="isGstReprintModalActive"
      :active.sync="isGstReprintModalActive"
      :show-icon="false"
      :gst-basis="gstBasis"
      @close="closeReprintModal()"
      @print="rePrint">
      <p slot="text-title">Reprint Previous Gst Report</p>/>
    </gst-reprint-modal>
    <gst-print-modal v-if="isGstPrintModalActive"
      :active.sync="isGstPrintModalActive"
      :show-icon="false"
      :gst-basis="gstBasis"
      :selected-month="selectedMonth"
      @close="closePrintModal()"
      @print="print">
      <p slot="text-title">Reports to Print</p>/>
    </gst-print-modal>
    <gst-pay-with-holding-modal v-if="isGstPayWHModalActive"
      :active.sync="isGstPayWHModalActive"
      :company-setting="companySetting"
      :gst-basis="gstBasis"
      :selected-month="selectedMonth"
      @close="closePayGstModal()"
      @saveSuccess="saveSuccess()">
      <p slot="text-title">Pay GST/With-Holding</p>/>
    </gst-pay-with-holding-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import { PrintPreviewWithoutHeader } from '@/components/printpreview'
import { CompanySettingService } from '@/services'
import GstReportSideMenu from './GstReportSideMenu'
import GstWithHoldingStatementModal from './GstWithHoldingStatementModal'
import GstReprintModal from './GstReprintModal'
import GstPrintModal from './GstPrintModal'
import GstPayWithHoldingModal from './GstPayWithHoldingModal'

export default {
  name: 'GstReportView',
  components: {
    AppDetailHeader,
    PrintPreviewWithoutHeader,
    [GstReportSideMenu.name]: GstReportSideMenu,
    GstWithHoldingStatementModal,
    GstReprintModal,
    GstPrintModal,
    GstPayWithHoldingModal
  },
  mixins: [],
  data() {
    return {
      gstPeriod: 12,
      gstBasis: null,
      companySetting: null,
      isConfirmModalActive: true,
      isGstWithHoldingModalActive: false,
      isGstReprintModalActive: false,
      isGstPrintModalActive: false,
      isGstPayWHModalActive: false,
      selectedMonth: null,
      loadReport: false,
      reports: [],
      printWithHold: false,
      reportType: null,
      enableSave: false,
      reportLoadFinish: false
    }
  },
  computed: {
    updateSuccess() {
      return this.enableSave && this.reportLoadFinish
    }
  },
  watch: {
    updateSuccess() {
      if (this.updateSuccess == true) {
        this.$notification.openNotificationWithType('success', 'GST Report', 'Do you want to UPDATE these transactions?')
      } else {
        return
      }
    }
  },
  async created() {
    this.companySetting = await this.getGstPeriod()
    this.gstPeriod = this.companySetting.gstPeriod
    this.enableSave = this.reportLoadFinish = false
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    async getGstPeriod() {
      let companySetting = await CompanySettingService.getEntity()
      this.gstPeriod = companySetting.gstPeriod
      this.gstBasis = companySetting.gstBasis
      return companySetting
    },
    openWithHoldingModal() {
      this.isGstWithHoldingModalActive = true
    },
    openReprintModal() {
      this.isGstReprintModalActive = true
    },
    openPrintModal(selectedMonth) {
      this.selectedMonth = selectedMonth
      this.isGstPrintModalActive = true
    },
    closePrintModal() {
      this.isGstPrintModalActive = false
    },
    closeWithHoldModal() {
      this.isGstWithHoldingModalActive = false
    },
    closeReprintModal() {
      this.isGstReprintModalActive = false
    },
    closePayGstModal() {
      this.isGstPayWHModalActive = false
    },
    printWH(reportList) {
      this.enableSave = false
      this.reportType = reportList[0].reportName
      this.reports = reportList
      this.isGstWithHoldingModalActive = false
      this.loadReport = true
    },
    rePrint(reportList) {
      this.enableSave = false
      this.reportType = reportList[0].reportName
      this.reports = reportList
      this.isGstReprintModalActive = false
      this.loadReport = true
    },
    print(reportList) {
      this.reportType = reportList[0].reportName
      this.reports = reportList
      this.isGstPrintModalActive = false
      this.loadReport = true
      this.enableSave = true
    },
    save() {
      this.isGstPayWHModalActive = true
    },
    tabClicked(reportType) {
      this.reportType = reportType
    },
    loadFinish() {
      this.reportLoadFinish = true
    },
    saveSuccess() {
      this.enableSave = false
    }
  }
}
</script>