<template>
  <div>
    <article class="tile is-child box detail-page tile">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <multiselect v-model="selectedDate"
              :class="{ 'is-danger' : $v.reprintFilter.endDate.$error }"
              deselect-label="Can't remove this value"
              :show-labels="false"
              :options="gstDates"
              :loading="isGstLoading"
              :internal-search="false"
              :searchable="false"
              :allow-empty="true"
              @select="selectGstDate"
              @remove="removeGstDate"
              placeholder="Select Period End Date" />
            <span
              class="help is-danger"
              v-if="$v.reprintFilter && !$v.reprintFilter.endDate.required">GST period end date is required.
            </span>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <input class="is-checkradio is-info"
              :class="{ 'has-background-color': selectedAcq }"
              v-model="selectedAcq"
              id="acqReport"
              type="checkbox"
              @click="selectAcqReport">
            <label for="acqReport">Acquisitions/Supplies Reports</label>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <input class="is-checkradio is-info"
              :class="{ 'has-background-color': selectedWithHold }"
              v-model="selectedWithHold"
              type="checkbox"
              id="withHold"
              @click="selectWithHoldReport">
            <label for="withHold">With-Holding Report</label>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { FocusInserted } from '@/components/directives'
import _cloneDeep from 'lodash/cloneDeep'
import GstReportService from './GstReportService'
import { DateTime } from 'luxon'
import StoreMixin from './storeMixin'

export default {
  name: 'GstReprintDetail',
  inject: ['$vv'],
  directives: {
    FocusInserted
  },
  components: {
    Multiselect
  },
  mixins: [StoreMixin],
  props: {
    value: null,
    gstBasis: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedDate: null,
      gstDates: [],
      selectedAcq: true,
      selectedWithHold: true,
      selectedReports: [],
      isGstLoading: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedDate(newVal, oldVal) {
      if (newVal) {
        this.setReprintSelectedDate(newVal)
      } else {
        this.setReprintSelectedDate()
      }
    }
  },
  async created() {
    this.innerValue.endDate = this.selectedDate = this.reprintSelectedDate
    this.getGstDateDropdown()
  },
  mounted() {
    this.$v.reprintFilter.$touch()
  },
  methods: {
    async selectGstDate(selected) {
      this.innerValue.endDate = this.selectedDate = selected
    },
    removeGstDate() {
      this.innerValue.endDate = this.selectedDate = null
    },
    selectAcqReport() {
      this.selectedAcq = this.innerValue.checkAcqSupplies = this.selectedAcq == false ? true : false
    },
    selectWithHoldReport() {
      this.selectedWithHold = this.innerValue.checkWithHold = this.selectedWithHold == false ? true : false
    },
    async getGstDateDropdown() {
      let result = await GstReportService.getGstDate(this.gstBasis)
      //this.gstDates = result.map(i => this.formatDateLocale(i, this.$userInfo.locale))
      this.gstDates = result.map(i => DateTime.fromISO(i).toISODate())
    },
    formatDateLocale(value, locale, format = '2-digit') {
      const date = new Date(Date.parse(`${value}`))
      const options = { year: 'numeric', month: format, day: '2-digit' }
      if (Intl) {
        return Intl.DateTimeFormat(locale, options).format(date)
      } else {
        return date.toLocaleDateString(locale, options)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
