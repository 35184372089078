import { required } from 'vuelidate/lib/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    reprintFilter: {
      endDate: {
        required
      }
    },
    withHoldStatementFilter: {
      selectedFromDate: {
        required
      },
      selectedToDate: {
        required
      },
      selectedVendor: {
        required
      }
    },
    entity: {
      paymentDate: {
        required
      }
    }
  }
}
