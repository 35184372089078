import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getGstDate(gstBasis) {
    const url = `/gstreports/gstbasis?gstbasis=${gstBasis}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getFromDate(gstToDate, gstBasis) {
    const url = `/gstreports/gsttodate?gsttodate=${gstToDate}&gstbasis=${gstBasis}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getGstPayEntity(startDate, endDate) {
    const url = `/gstreports/new?startDate=${startDate}&endDate=${endDate}`
    const result = await FqApi.get(url)
    return result.data
  },
  async checkGstTransaction(filter) {
    const serialised = this.serialiseGstReportCheckFilter(filter)
    const url = `/gstreports/isgsttransaction/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseGstReportCheckFilter(filter) {
    const gstReportCheckProto = require('../../assets/proto/gstreport/GstReportCheckFilter.proto')
    const filterMessage = gstReportCheckProto.GstReportCheckFilter.GstReportCheckFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async postEntity(entity) {
    return FqApi.post('/gstreports/new', entity)
  }
}