<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    :min-height="35"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <gst-reprint-detail v-model="reprintFilter"
        :gst-basis="gstBasis" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="print()"
        data-tooltip="Print">Print</button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Cancel">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { mapGetters } from 'vuex'
import storeMixin from './storeMixin'
import _debounce from 'lodash.debounce'
import { KeyValuePairModel } from '@/classes/viewmodels'
import GstReprintDetail from './GstReprintDetail'
import GstReportValidation from './GstReportValidation'
import GstReportService from './GstReportService'
import { DateTime } from 'luxon'

export default {
  name: 'GstReprintModal',
  components: {
    BaseModalEx,
    GstReprintDetail
  },
  mixins: [GstReportValidation, storeMixin],
  props: {
    gstBasis: {
      type: String,
      default: ''
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    buttonLocation: {
      type: String,
      default: 'end'
    }
  },
  data() {
    return {
      reprintFilter: {
        gstBasis: this.gstBasis,
        startDate: null,
        endDate: null,
        type: 'REPRINT',
        checkAcqSupplies: true,
        checkWithHold: true
      },
      isActive: true
    }
  },
  computed: {
    ...mapGetters(['licenceExpired']),
    validateError() {
      return this.$v.$error || this.$v.reprintFilter.$error
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    reprintFilter: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
        }
      }, 500),
      deep: true
    }
  },
  async created() {},
  methods: {
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|gstreport|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|gstreport|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    close() {
      this.clearSessionStorage()
      this.$emit('close')
    },
    async print() {
      const title = 'GST Report'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      this.reprintFilter.startDate = await GstReportService.getFromDate(this.reprintFilter.endDate, this.gstBasis)
      let checkPrint = await GstReportService.checkGstTransaction(this.reprintFilter)

      const paramsSupply = {
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: 'GST Supplies Report',
        STdate: DateTime.fromISO(this.reprintFilter.startDate).toISODate(),
        ENDdate: DateTime.fromISO(this.reprintFilter.endDate).toISODate(),
        COMPANYID: this.$userInfo.companyId,
        GSTType: 'REPRINT',
        GSTBasis: this.gstBasis
      }

      const paramsAcq = {
        // STdate: this.reprintFilter.startDate,
        // ENDdate: this.reprintFilter.endDate,
        STdate: DateTime.fromISO(this.reprintFilter.startDate).toISODate(),
        ENDdate: DateTime.fromISO(this.reprintFilter.endDate).toISODate(),
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: 'GST Acquisitions Report',
        COMPANYID: this.$userInfo.companyId,
        GSTType: 'REPRINT',
        GSTBasis: this.gstBasis
      }

      const paramWithHold = {
        // STdate: this.reprintFilter.startDate,
        // ENDdate: this.reprintFilter.endDate,
        STdate: DateTime.fromISO(this.reprintFilter.startDate).toISODate(),
        ENDdate: DateTime.fromISO(this.reprintFilter.endDate).toISODate(),
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: 'With-Holding Report',
        COMPANYID: this.$userInfo.companyId,
        GSTType: 'REPRINT'
      }

      let reportSupply = {
        parameters: KeyValuePairModel.convertToKeyValuePairs(paramsSupply),
        parameterId: this.$guid.newGuid(),
        reportName: 'AT_GSTSuppliesReportV2',
        reportTitle: 'GST Supplies Report'
      }

      let reportAcq = {
        parameters: KeyValuePairModel.convertToKeyValuePairs(paramsAcq),
        parameterId: this.$guid.newGuid(),
        reportName: 'AT_GSTAcquisitionsV2',
        reportTitle: 'GST Acquisitions Report'
      }

      let reportWithHold = {
        parameters: KeyValuePairModel.convertToKeyValuePairs(paramWithHold),
        parameterId: this.$guid.newGuid(),
        reportName: 'AT_GSTWithHoldingV2',
        reportTitle: 'With-Holding Report'
      }
      let reportList = []

      if (checkPrint.isSuppliesRecord) {
        reportList.push(reportSupply)
      }
      if (checkPrint.isAcqRecord) {
        reportList.push(reportAcq)
      }
      if (checkPrint.isGstWithHold) {
        reportList.push(reportWithHold)
      }

      if (reportList.length == 0) {
        this.$notification.openNotificationWithType('danger', title, 'There are no transactions to report for this period!')
      } else {
        this.$emit('print', reportList)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
