import { render, staticRenderFns } from "./GstWithHoldingStatementModal.vue?vue&type=template&id=7b47eda9&scoped=true"
import script from "./GstWithHoldingStatementModal.vue?vue&type=script&lang=js"
export * from "./GstWithHoldingStatementModal.vue?vue&type=script&lang=js"
import style0 from "./GstWithHoldingStatementModal.vue?vue&type=style&index=0&id=7b47eda9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b47eda9",
  null
  
)

export default component.exports