<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    :min-height="35"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <gst-pay-with-holding-detail v-if="entity"
        v-model="entity"
        :gst-basis="gstBasis" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="save()"
        data-tooltip="Save">Save</button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Cancel">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { mapGetters } from 'vuex'
import storeMixin from './storeMixin'
import HttpStatus from '@/components/http-status'
import GstPayWithHoldingDetail from './GstPayWithHoldingDetail'
import GstReportValidation from './GstReportValidation'
import GstReportService from './GstReportService'

export default {
  name: 'GstPayWithHoldingModal',
  components: {
    BaseModalEx,
    GstPayWithHoldingDetail
  },
  mixins: [GstReportValidation, storeMixin],
  props: {
    gstBasis: {
      type: String,
      default: ''
    },
    companySetting: null,
    selectedMonth: null,
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: true,
      entity: null
    }
  },
  computed: {
    ...mapGetters(['licenceExpired']),
    validateError() {
      return this.$v.entity.$error
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  async created() {
    this.entity = await GstReportService.getGstPayEntity(this.selectedMonth.firstDayISO, this.selectedMonth.lastDayISO)
    this.entity.companySetting = this.companySetting
  },
  methods: {
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|gstreport|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|gstreport|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    close() {
      this.clearSessionStorage()
      this.$emit('close')
    },
    async save() {
      const title = 'Print GST Report'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }
      try {
        this.$showSpinner('Saving...')
        let response
        response = await GstReportService.postEntity(this.entity)
        this.$hideSpinner()
        if (this.isSaveContinue) {
          this.$notification.openNotificationWithType('success', title, isDelete ? 'GST deleted' : 'Save successful')
        } else if (response && response.status === HttpStatus.NO_CONTENT) {
          this.$notification.openNotificationWithType('success', title, 'Update successful')
          this.$emit('saveSuccess')
        }
        this.close()
        return true
      } catch (e) {
        this.$hideSpinner()
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('GST Report', e)
        }
        this.$notification.openMessageXhrError('Print GST Report', e)
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
