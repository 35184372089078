<template>
  <div>
    <article class="tile is-child box detail-page tile">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <input class="is-checkradio is-info"
              :class="{ 'has-background-color': selectedAcq }"
              v-model="selectedAcq"
              id="acqReport"
              type="checkbox"
              @click="selectAcqReport">
            <label for="acqReport">Acquisitions/Supplies Reports</label>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <input class="is-checkradio is-info"
              :class="{ 'has-background-color': selectedWithHold }"
              v-model="selectedWithHold"
              type="checkbox"
              id="withHold"
              @click="selectWithHoldReport">
            <label for="withHold">With-Holding Report</label>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { FocusInserted } from '@/components/directives'
import _cloneDeep from 'lodash/cloneDeep'
// import GstReportService from './GstReportService'

export default {
  name: 'GstPrintDetail',
  inject: ['$vv'],
  directives: {
    FocusInserted
  },
  components: {},
  mixins: [],
  props: {
    value: null,
    gstBasis: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedDate: null,
      gstDates: [],
      selectedAcq: true,
      selectedWithHold: true,
      selectedReports: [],
      isGstLoading: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  async created() {
    // this.getGstDateDropdown()
  },
  mounted() {},
  methods: {
    async selectGstDate(selected) {
      this.innerValue.selectedToDate = selected
    },
    removeGstDate() {
      this.innerValue.selectedToDate = null
    },
    selectAcqReport() {
      if (this.selectedAcq == false) {
        this.selectedAcq = true
        this.innerValue.checkAcqSupplies = true
        return
      } else if (this.selectedAcq == true) {
        this.selectedAcq = false
        this.innerValue.checkAcqSupplies = false
        return
      }
    },
    selectWithHoldReport() {
      if (this.selectedWithHold == false) {
        this.selectedWithHold = true
        this.innerValue.checkWithHold = true
        return
      } else if (this.selectedWithHold == true) {
        this.selectedWithHold = false
        this.innerValue.checkWithHold = false
        return
      }
    },
    // async getGstDateDropdown() {
    //   let result = await GstReportService.getGstDate(this.gstBasis)
    //   this.gstDates = result.map(i => this.formatDateLocale(i, this.$userInfo.locale))
    // },
    formatDateLocale(value, locale, format = '2-digit') {
      const date = new Date(Date.parse(`${value}`))
      const options = { year: 'numeric', month: format, day: '2-digit' }
      if (Intl) {
        return Intl.DateTimeFormat(locale, options).format(date)
      } else {
        return date.toLocaleDateString(locale, options)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
