<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    :min-height="55"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <gst-with-holding-statement-detail v-model="withHoldStatementFilter" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="print()"
        data-tooltip="Print">Print</button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Cancel">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { mapGetters } from 'vuex'
import storeMixin from './storeMixin'
import { KeyValuePairModel } from '@/classes/viewmodels'
import GstWithHoldingStatementDetail from './GstWithHoldingStatementDetail'
import GstReportValidation from './GstReportValidation'
import { DateTime } from 'luxon'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'

export default {
  name: 'GstWithHoldingStatementModal',
  components: {
    BaseModalEx,
    GstWithHoldingStatementDetail
  },
  mixins: [GstReportValidation, storeMixin],
  props: {
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      withHoldStatementFilter: {
        selectedFromDate: null,
        selectedToDate: null,
        selectedVendor: null
      },
      isActive: true
    }
  },
  computed: {
    ...mapGetters(['licenceExpired']),
    validateError() {
      return this.$v.$error || this.$v.withHoldStatementFilter.$error
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  async created() {},
  methods: {
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|gstreport|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|gstreport|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    close() {
      this.clearSessionStorage()
      this.$emit('close')
    },
    print() {
      const title = 'GST Report'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }
      const params = {
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: 'WITH-HOLDING STATEMENT',
        STdate: DateTime.fromISO(this.withHoldStatementFilter.selectedFromDate).toISODate(),
        ENDdate: DateTime.fromISO(this.withHoldStatementFilter.selectedToDate).toISODate(),
        CompanyID: this.$userInfo.companyId,
        VendorID: this.withHoldStatementFilter.selectedVendor.id
      }

      const emailer = new Emailer()
      emailer.subject = 'GST With-Holding Statement'
      emailer.reportName = 'AT_GSTPaymentSummaryV2'
      this.addEmailer(emailer)

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId:  this.$guid.newGuid(), emailerId: emailer.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
