import { mapGetters, mapActions } from 'vuex'

const moduleName = 'gstreport'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff',
      whPeriodStart: 'whPeriodStart',
      whPeriodEnd: 'whPeriodEnd',
      reprintSelectedDate: 'reprintSelectedDate'
    }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    ...mapActions(moduleName, [
      'getStoreItem',
      'addStoreItem',
      'saveSnapshot',
      'clearSnapshots',
      'setWHPeriodStart',
      'setWHPeriodEnd',
      'setReprintSelectedDate']),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  }
}
